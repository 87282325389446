// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_tx d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_ty d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_tz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_tB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_tC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_tD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_tF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_r7 q_r7";
export var heroExceptionNormal = "t_r8 q_r8";
export var heroExceptionLarge = "t_r9 q_r9";
export var Title1Small = "t_rL q_rL q_rf q_rg";
export var Title1Normal = "t_rM q_rM q_rf q_rh";
export var Title1Large = "t_rN q_rN q_rf q_rj";
export var BodySmall = "t_rZ q_rZ q_rf q_ry";
export var BodyNormal = "t_r0 q_r0 q_rf q_rz";
export var BodyLarge = "t_r1 q_r1 q_rf q_rB";