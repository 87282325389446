// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_vY";
export var lbContainerInner = "B_vZ";
export var movingForwards = "B_v0";
export var movingForwardsOther = "B_v1";
export var movingBackwards = "B_v2";
export var movingBackwardsOther = "B_v3";
export var lbImage = "B_v4";
export var lbOtherImage = "B_v5";
export var prevButton = "B_v6";
export var nextButton = "B_v7";
export var closing = "B_v8";
export var appear = "B_v9";