// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_tY d_bD";
export var storyRowWrapper = "x_hx d_hx d_bK";
export var storyLeftWrapper = "x_tZ d_bz d_bP";
export var storyWrapperFull = "x_t0 d_cD";
export var storyWrapperFullLeft = "x_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "x_mv d_hy";
export var storyLeftWrapperCenter = "x_t1 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "x_t2 d_hF";
export var storyHeader = "x_t3 d_hD d_w d_cs";
export var storyHeaderCenter = "x_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "x_hB d_hB d_by d_dw";
export var storyBtnWrapper = "x_t4 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "x_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "x_t5 d_fg d_Z";
export var imageWrapperFull = "x_t6 d_w d_H d_bf d_Z";
export var SubtitleSmall = "x_qd q_qd q_rf q_rr";
export var SubtitleNormal = "x_qf q_qf q_rf q_rs";
export var SubtitleLarge = "x_qg q_qg q_rf q_rt";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";