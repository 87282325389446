// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pB";
export var searchResult = "n_qN n_pB";
export var line = "n_fk d_fk d_cY";
export var articleTitle = "n_qP d_cq";
export var articleText = "n_pC d_cs";
export var authorImage = "n_ln d_ln d_b7 d_Z d_ct";
export var authorText = "n_p2 d_ch d_ct";
export var textCenter = "n_dw d_dw";
export var searchWrapper = "n_qQ d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "n_qR d_cF d_w d_b4";
export var searchBtn = "n_qS d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "n_qc d_cr";
export var elementWrapper = "n_my d_cl";
export var select = "n_qT d_w d_b4";
export var articleImageWrapper = "n_pT d_cs d_Z d_V";
export var articleImageWrapperIcon = "n_pV d_cs d_Z d_V";
export var articleImageWrapperColumns = "n_qV d_cs d_fh d_Z";
export var contentPadding = "n_qW d_cS";
export var otherPadding = "n_qX d_cM";
export var paginationWrapper = "n_qY";
export var pagination = "n_qZ";
export var breakMe = "n_q0";
export var activePage = "n_q1";
export var next = "n_q2";
export var pages = "n_q3";
export var searchTitle = "n_q4 d_cr";
export var categoryTitle = "n_q5 d_cr d_Z";
export var searchText = "n_q6 d_ct";
export var bold = "n_q7";
export var field = "n_q8";
export var dateTag = "n_pL d_l d_cr d_dq";
export var icon1 = "n_pM d_cP";
export var icon2 = "n_pN d_cP d_cJ";
export var tagBtn = "n_pP d_b1 d_cD d_dp d_dB";
export var linksWrapper = "n_p5 d_ch d_dw";
export var header = "n_pD d_w d_Z";
export var headerInner = "n_q9";
export var headerImage = "n_rb d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "n_p1 d_cY";
export var resultImage = "n_rc";